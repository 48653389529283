.cardCustomise {
  .cardsContainer {
    width: 100%;
    height: 21rem;
    max-height: 21rem;
    overflow-y: auto;

    &.teamBoxes {
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &.countryBoxes {
      align-items: flex-start;
      justify-content: center;
      gap: 3.275%;
    }
  }

  .countryContainer {
    margin-top: 3rem;
  }

  .headingContainer {
    margin: 1rem 0;
  }
}

.searchBox {
  height: 1.5rem;
  width: 23.75%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  border-bottom: 0.1rem solid white;

  img {
    height: 1rem;
    width: 1rem;
  }

  input {
    margin-left: 0.75rem;
    height: 100%;
    width: calc(100% - 1.75rem);
    background-color: transparent !important;
    color: $color-text !important;

    &::placeholder {
      font-size: 1rem;
      line-height: 1.5;
      color: #5e5e5e;
    }
  }

  //input:-webkit-autofill,
  //input:-webkit-autofill:hover,
  //input:-webkit-autofill:focus,
  //input:-webkit-autofill:active{
  //  -webkit-box-shadow:0 0 0 50px $color-container-bg inset; /* Change the color to your own background color */
  //
  //  background-color: transparent!important;
  //  color: white!important;
  //  -webkit-text-fill-color: white;
  //
  //}
}

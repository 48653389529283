.cardChoice {
  .cardsContainer {
    width: 100%;
  }

  .cardsContainerLg {
    height: 21.5rem;
    max-height: 21.5rem;
    overflow-y: auto;
  }
}

.teamCard {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.75rem 1.75rem;
  margin-bottom: 0.75rem;

  border: 1px solid white;
  cursor: pointer;
  user-select: none;
  //height: 27%;
  height: 20.574%;

  &.active {
    border-color: $color-primary;
  }

  img {
    width: 2.66rem;
    height: 2.66rem;
    object-fit: contain;
  }

  &__text {
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 2rem - 2.66rem - 1.5rem);

    h2,
    h3 {
      font-size: 1rem;
      font-weight: 400;
      color: white;
    }
  }
}

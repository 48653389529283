.checkout {
  min-height: 100vh;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .doted-bg {
    position: absolute;
    top: 0;
    right: 0;
    left: unset;
    height: 100%;
    width: 49%;

    @media screen and (max-width: 990px) {
      width: 100%;
    }
  }

  .container {
    //border: 1px solid red;
    padding: 5rem 0;

    @media screen and (min-width: 990px) {
      max-width: 102.5rem;
    }

    @media screen and (max-width: 990px) {
      flex-flow: column;
    }
  }

  &__about {
    //border: 1px solid red;
    width: 51%;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 990px) {
      width: 100%;
      max-width: 380px;
      align-items: center;
      justify-content: center;
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;

      @media screen and (max-width: 990px) {
        align-self: flex-start;
      }

      &__arrow {
        width: 1.33rem;
      }

      &__logo {
        width: 2.66rem;
        height: 2.66rem;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;

        margin-left: 1rem;
        margin-right: 1.66rem;

        img {
          width: 54%;
          height: 54%;
        }
      }

      h4 {
        font-size: 1.16rem;
        font-weight: 500;
      }
    }

    &__prodName {
      font-size: 1.33rem;
      font-weight: 500;
      margin-top: 3.33rem;
      margin-left: 2rem;

      @media screen and (max-width: 990px) {
        margin-left: 0;
      }
    }

    &__prodPrice {
      font-size: 3rem;
      font-weight: 400;
      line-height: 1.22;
      margin-top: 0.66rem;
      margin-left: 2rem;

      @media screen and (max-width: 990px) {
        margin-bottom: 1rem;
        margin-left: 0;
      }
    }

    &__prodImg {
      width: 64%;
      margin-top: 4rem;

      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 990px) {
        display: none;
      }

      img {
        width: 62.5%;
        height: auto;
      }
    }
  }

  &__form {
    width: 49%;

    @media screen and (max-width: 990px) {
      width: 100%;
      max-width: 380px;
    }

    .checkoutForm {
      margin-left: auto;
      width: 86%;

      @media screen and (max-width: 990px) {
        width: 100%;
      }

      h5 {
        font-size: 16px;
        margin-top: 2.66rem;
        text-align: center;
        font-weight: 400;
      }

      .formGroup {
        margin-top: 2.66rem;

        input,
        select {
          width: 100%;
          color: #424770;

          &::placeholder {
            color: #8792a2;
          }
        }
      }

      label {
        color: white;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.57;
        margin-bottom: 8px;

        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      input,
      select,
      button,
      .StripeElement {
        display: block;
        margin: 0;
        //max-width: 500px;
        padding: 0.75rem 1rem;
        font-size: 1.4rem;
        font-family: 'Poppins', monospace;
        border: 1px solid rgba(60, 66, 87, 0.12);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        //border: 0;
        outline: 0;
        background-color: white;

        &:focus,
        &:active,
        &--focus {
          position: relative;
          z-index: 99999;
          outline: none;
          box-shadow: 0 0 0 1px rgba(#46f708, 0.5), 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 4px rgba(#46f708, 0.5);
        }
      }

      .cardNumber {
        //width: 100%;
        //border-bottom-left-radius: 0;
        //border-bottom-right-radius: 0;
      }

      .cardCvcNumber,
      .cardExpiryNumber {
        //width: 50%;
        //border-top-left-radius: 0;
        //border-top-right-radius: 0;
      }

      .cardExpiryNumber {
        border-bottom-right-radius: 0;
      }

      .cardCvcNumber {
        border-bottom-left-radius: 0;
      }

      .paymentReqButton {
        background-color: transparent;
      }

      .borderBottomRadius-0 {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .borderTopRadius-0 {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .btn {
        margin-top: 2.66rem;
        background-color: $color-primary;
        width: 100%;
        display: block;
        box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12), 0px 2px 5px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        font-size: 1.5rem;
        font-weight: 500;
        height: 4rem;

        .lds-ellipsis {
          height: 1rem;

          div {
            top: 0;
          }
        }
      }

      .StripeElement--invalid,
      .input--invalid {
        outline: 1px solid #ff1c1c;
        color: #ff1c1c;
      }

      .error--sm {
        font-size: 10px;
        color: #ff1c1c;
      }

      .inputDisabled {
        //color: rgba(26, 26, 26, .4);
        //transition: background-color 100000000s, box-shadow 0s linear;
        box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%),
          inset 0 0 0 1000px rgb(26 26 26 / 10%);
        pointer-events: none;
        -webkit-text-fill-color: rgba(26, 26, 26, 0.4);
        color: rgba(26, 26, 26, 0.4);
      }
    }
  }

  &__footer {
    margin-top: 7.5rem;
    margin-left: 2rem;

    @media screen and (max-width: 990px) {
      display: none;
    }

    &__stripe {
      p {
        font-size: 1rem;
        font-weight: 500;
        color: #8792a2;
        letter-spacing: 0.015em;
        font-feature-settings: 'tnum' on, 'lnum' on;
        margin-right: 0.66rem;

        @media screen and (max-width: 990px) {
          font-size: 1.5rem;
        }
      }

      svg {
        width: 34px;
        height: 16px;
        fill: #8792a2;
      }
    }

    &__line {
      width: 0.084rem;
      height: 2rem;
      background-color: #8792a2;
      margin-left: 2.5rem;
      margin-right: 1.8rem;

      @media screen and (max-width: 990px) {
        display: none;
      }
    }

    &__links {
      a {
        color: #8792a2;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.8;

        @media screen and (max-width: 990px) {
          font-size: 1.5rem;
          margin-top: 1rem;
        }
      }
    }
  }

  .footerBottom {
    @media screen and (min-width: 990px) {
      display: none;
    }
    @media screen and (max-width: 990px) {
      display: flex;
      flex-flow: column;
      margin-top: 0.6rem;
      margin-left: 0;
    }
  }
}

.cardFinalize {
  .creatorContentContainer {
    padding: 1rem 2rem 2.5rem;
    width: 90%;

    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  &.creatorHalfContainer {
    @media screen and (max-width: 400px) {
      width: 100%;
    }
  }

  &__cardSizes {
    flex-flow: row wrap;
  }

  &__paymentBtns {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: 700px) {
      flex-flow: column;
      align-items: center;
      justify-content: center;

      width: 100%;
    }

    &__methods {
      @media screen and (max-width: 700px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      img {
        width: 2.83rem;
        height: auto;
        margin-right: 0.75rem;

        @media screen and (max-width: 700px) {
          max-width: 7rem;
          width: 15%;
          margin: 0;
        }
      }
    }

    .btn {
      width: 35%;

      @media screen and (max-width: 700px) {
        margin-top: 2rem;
        width: 70%;
        padding: 1rem 0;
        font-size: 1.5rem;
      }
    }
  }

  .headingContainer {
    @media screen and (max-width: 700px) {
      margin: 1rem 0;

      h4 {
        font-size: 2rem;
      }
    }
  }
}

main {
  width: 100%;

  h2 {
    text-align: center;
  }
}

.newsletterPromo {
  width: 100%;
  padding: 1.5rem;
  border: 0.085rem solid $color-primary;
  box-shadow: 0 0.33rem 0.66rem rgba(0, 0, 0, 0.35);

  p {
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      text-decoration: underline;
    }
  }

}


// Hero Section Style
.hero {
  position: relative;
  width: 100%;
  height: 890px;
  padding-top: 83px;
  background-color: black;
  background-size: cover;
  background-position: 100% 25%;

  @media screen and (max-width: 1740px) {
    height: 780px;
  }

  @media screen and (max-width: 1560px) {
    height: 730px;
  }

  @media screen and (max-width: 1400px) {
    height: 690px;
  }

  @media screen and (max-width: 1200px) {
    height: 570px;
  }

  @media screen and (max-width: 1000px) {
    background-size: auto 530px;
    height: 500px;
    padding-top: 42px;
  }

  @media screen and (max-width: 770px) {
    background-position: 35% 25%;
    background-size: auto 900px;
    height: auto;
    padding: 42px 0 16px;
  }

  @media screen and (max-width: 400px) {
    background-size: auto 530px;
  }


  &__container {
    color: white;
    width: 100%;
    padding-left: 130px;

    display: flex;
    flex-flow: column;

    @media screen and (max-width: 950px) {
      padding-left: 60px;
      padding-right: 60px;
    }

    @media screen and (max-width: 770px) {
      padding-left: 25px;
      padding-right: 25px;
    }

    h2 {
      // width: 45%;
      // min-width: 990px;
      font-size: 5.3rem;
      line-height: 1.5;
      text-shadow: 0 4px 4px rgba(0, 0, 0, 0.45);
      font-weight: 600;
      font-style: normal;
      text-align: start;

      @media screen and (max-width: 1700px) {
        font-size: 5rem;
      }

      @media screen and (max-width: 1400px) {
        font-size: 4.5rem;
      }

      @media screen and (max-width: 1400px) {
        font-size: 3.5rem;
      }

      @media screen and (max-width: 400px) {
        font-size: 1.9rem;
      }

      span {
        font-weight: 600;

        background: linear-gradient(269.97deg, #E1F600 -29.99%, #46F708 55.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        //background-clip: text;
        //text-fill-color: transparent;
        text-shadow: none;
      }

    }

    p {
      font-size: 1.5rem;
      font-weight: 400;
      // line-height: 27px;

      @media screen and (max-width: 400px) {
        font-size: 0.8rem;

      }

      span {
        font-size: 2rem;
        font-weight: 400;
        font-style: normal;
        line-height: 1.8;
        width: 39%;
        display: block;

        @media screen and (max-width: 1700px) {
          width: 35%;
        }

        @media screen and (max-width: 1200px) {
          width: 50%;
        }

        @media screen and (max-width: 770px) {
          width: 60%;
        }

        @media screen and (max-width: 400px) {
          font-size: 1.3rem;
          line-height: 22.5px;
          width: 70%;
        }
      }
    }

    .reverse {
      @media screen and (max-width: 770px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
      }


      @media screen and (max-width: 400px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
      }
    }

    .ratingHero {
      display: flex;
      align-items: center;
      margin-top: 30px;

      @media screen and (max-width: 1200px) {
        margin-top: 20px;

        img {
          height: 24px;
          width: auto;
        }
      }

      @media screen and (max-width: 770px) {
        flex-direction: column;
        margin-top: 30px;
      }

      @media screen and (max-width: 400px) {
        img {
          height: 13px;
          width: auto;
        }
      }

      p {
        margin-left: 9px;
        font-weight: 400;
        font-size: 1.33rem;
        line-height: 24px;

        color: rgba(255, 255, 255, 0.81);

        @media screen and (max-width: 770px) {
          margin-left: 0;
          margin-top: 10px;
        }

        @media screen and (max-width: 400px) {
          margin-left: 0;
          margin-top: 10px;
          font-size: 0.6rem;
          line-height: 9px;
        }
      }
    }

    .herobtn {
      width: 250px;
      height: 65px;
      margin-top: 38px;
      background: #fff;
      border-radius: 40px;

      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 27px;

      color: #46F708;

      @media screen and (max-width: 1400px) {
        width: 190px;
        height: 50px
      }

      @media screen and (max-width: 770px) {
        margin-top: 15px;
      }

      @media screen and (max-width: 400px) {
        height: 44px;
        width: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        // align-self: center;
        font-weight: 700;
        font-size: 1rem;
        line-height: 15px;
      }
    }

    .cards {

      &.desktop-cards {
        position: absolute;
        right: 120px;
        top: 130px;
        width: 67%;
        height: auto;

        @media screen and (max-width: 1700px) {
          right: 90px;
          top: 130px;
        }

        @media screen and (max-width: 1200px) {
          right: 80px;
          top: 160px;
          width: 58%;
          height: auto;
        }


        @media screen and (max-width: 770px) {
          display: none;
        }

      }

      &.mobile-cards {
        transform: rotate(-3deg);

        @media screen and (min-width: 770px) {
          display: none;
        }
      }
    }
  }

}

// Reviews Section Style
.reviews {
  background-color: #fff;

  .reviews__container {
    color: black;
    width: 100%;
    display: flex;
    flex-flow: column;

    .logo {
      padding-top: 75px;
      padding-left: 130px;

      img {
        // height: 132px;
        // width: 320px;
        height: auto;
        width: 20%;
      }

      @media screen and (max-width: 780px) {

        padding-top: 38px;
        padding-left: 38px;

        img {
          height: 78px;
          width: auto;

        }
      }

      @media screen and (max-width: 400px) {

        padding-top: 38px;
        padding-left: 38px;

        img {
          height: 38px;
          width: 93px;

        }
      }
    }

    .review {
      .carosule .splide__arrow {
        opacity: 1;
        background-color: transparent;
        height: 50px;
        width: 50px;
        transform: translateY(-144%);

      }

      .splide__arrow--prev {
        left: 33rem;
      }

      .splide__arrow--next {
        right: 33rem;
      }

      @media screen and (max-width: 1600px) {
        .splide__arrow--prev {
          left: 30rem;
        }

        .splide__arrow--next {
          right: 30rem;
        }
      }

      @media screen and (max-width: 1400px) {
        .splide__arrow--prev {
          left: 20rem;
        }

        .splide__arrow--next {
          right: 20rem;
        }
      }

      @media screen and (max-width: 1200px) {
        .splide__arrow--prev {
          left: 15rem;
        }

        .splide__arrow--next {
          right: 15rem;
        }
      }

      @media screen and (max-width: 990px) {
        .splide__arrow--prev {
          left: 10rem;
        }

        .splide__arrow--next {
          right: 10rem;
        }
      }

      @media screen and (max-width: 850px) {
        .carosule .splide__arrow {
          height: 40px;
          width: 40px;
        }

        .splide__arrow--prev {
          left: 3rem;
        }

        .splide__arrow--next {
          right: 3rem;
        }
      }

      @media screen and (max-width: 650px) {
        .carosule .splide__arrow {
          height: 30px;
          width: 30px;
        }

        .splide__arrow--prev {
          left: 1rem;
        }

        .splide__arrow--next {
          right: 1rem;
        }
      }

      @media screen and (max-width: 550px) {
        margin-top: 18px;

        .carosule .splide__arrow {
          display: none;
        }

        .splide__pagination {
          top: -88px;
          left: 0;
          padding: 0 1em;
          position: absolute;
          right: 0;
          z-index: 1;
        }

        .splide__pagination__page {
          opacity: 1;
        }

        .splide__pagination__page.is-active {
          background: #000;

        }
      }
    }

    .reviewslide {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        margin: 0 auto;
        width: 868px;
        font-style: normal;
        font-weight: 600;
        font-size: 2.5rem;
        line-height: 1.5;
        text-align: center;

        @media screen and (max-width: 1600px) {
          width: 768px;
        }

        @media screen and (max-width: 1100px) {
          width: 600px;
        }

        @media screen and (max-width: 730px) {
          width: 80%;
        }

        @media screen and (max-width: 550px) {
          font-style: normal;
          width: 70%;
          min-width: 280px;
          font-weight: 600;
          font-size: 10px;
          text-align: center;
          margin-top: 38px;
        }

      }


      span {
        font-style: normal;
        font-weight: 300;
        font-size: 2.5rem;
        line-height: 1.5;
        text-align: center;

        color: rgba(96, 95, 95, 0.63);

        @media screen and (max-width: 550px) {
          font-weight: 300;
          font-size: 10px;
          margin-top: 5px;

        }
      }

      img {
        height: 25px;
        width: auto;
        margin-top: 40px;

        @media screen and (max-width: 550px) {
          display: none;

        }
      }

    }
  }
}


// Card Style
.carddesign {
  margin-top: -1px;
  width: 100%;
  // height: 980px;
  background-color: #fff;

  @media screen and (min-width: 770px) {
    padding-top: 0;
  }

  @media screen and (max-width: 400px) {
    height: 100%;
  }

  .carddesign__container {
    clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    color: white;
    width: 100%;
    padding-left: 130px;
    background-color: black;

    display: flex;

    @media screen and (max-width: 950px) {
      padding-left: 60px;
      // padding-right: 60px;
    }

    @media screen and (max-width: 770px) {
      padding-left: 30px;
      padding-right: 30px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
    }

    .left {
      flex: 1 1;

      @media screen and (max-width: 770px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      @media screen and (max-width: 400px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      h2 {
        padding-top: 270px;
        font-style: normal;
        text-align: start;
        font-weight: 600;
        font-size: 5.3rem;
        line-height: 96px;

        span {
          font-weight: 600;
          background: linear-gradient(269.97deg, #E1F600 -29.99%, #46F708 55.37%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          text-shadow: none;
        }

        @media screen and (max-width: 1740px) {
          padding-top: 230px;
          line-height: 66px;
          font-size: 5rem;
        }

        @media screen and (max-width: 1540px) {
          padding-top: 200px;
          line-height: 59px;
          font-size: 5rem;
        }

        @media screen and (max-width: 1370px) {
          padding-top: 150px;
          line-height: 54px;
          font-size: 4.5rem;
        }

        @media screen and (max-width: 1100px) {
          padding-top: 100px;
          line-height: 40px;
        }

        @media screen and (max-width: 800px) {
          padding-top: 80px;
        }

        @media screen and (max-width: 770px) {
          padding-top: 150px;
        }

        @media screen and (max-width: 400px) {
          padding-top: 90px;
          font-size: 1.6rem;
          line-height: 24px;
        }

      }

      .para {

        padding-top: 23px;
        width: 80%;

        p {
          font-style: normal;
          font-weight: 300;
          font-size: 2rem;
          line-height: 36px;


        }

        @media screen and (max-width: 1370px) {
          padding-top: 15px;
          width: 90%;

          p {

            font-size: 1.8rem;
            line-height: 26px;


          }

          br {
            display: none;
          }
        }

        @media screen and (max-width: 970px) {
          .hidden {
            display: none;
          }
        }

        @media screen and (max-width: 770px) {
          padding-top: 8px;
          width: 70%;
          text-align: center;
        }

        @media screen and (max-width: 400px) {
          line-height: 1.5;
        }

        @media screen and (max-width: 400px) {
          padding-top: 8px;
          width: 70%;
          text-align: center;


          br {
            display: none;
          }

          .hidden {
            display: none;
          }

          p {

            font-size: 0.9rem;
            line-height: 12px;
          }
        }
      }

      .cardbtn {
        width: 250px;
        height: 65px;
        margin-top: 38px;
        background: #fff;
        border-radius: 40px;

        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 27px;

        color: #46F708;

        @media screen and (max-width: 1100px) {
          margin-top: 25px;
        }

        @media screen and (max-width: 1370px) {
          width: 200px;
          height: 52px;
        }

        @media screen and (max-width: 400px) {
          height: 33px;
          width: 104px;
          font-size: 0.9rem;
          line-height: 14px;
          margin-bottom: 29px;
          margin-top: 16px;
        }
      }
    }

    .right {
      flex: 1 1;
      overflow: hidden;

      img {
        // margin-top: -10px;
      }

      @media screen and (max-width: 770px) {
        display: flex;
        justify-content: center;
        padding-top: 20px;
      }

      @media screen and (max-width: 1740px) {
        img {
          width: 95%;
          height: auto;
          margin-top: 10px;
        }
      }

      @media screen and (max-width: 400px) {

        // transform: scale(0.35);
        // width: 350px;
        img {
          width: 350px;
        }
      }
    }
  }
}


// Section Gift
.sectionGift {
  width: 100%;
  height: 52rem;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0% 100%);
  margin-top: -4%;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 650px) {
    padding: 7rem 0;
    margin-top: -12%;

    height: 100%;
    min-height: 52rem;
  }


  .container {
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }

  &__text {
    width: 55%;
    //margin-right: 6.5rem;

    @media screen and (max-width: 650px) {
      order: 2;
      width: 85%;
      margin-top: 2rem;
      text-align: center;
    }


    h2 {
      text-align: left;
      font-size: 3rem;
      font-weight: 600;

      @media screen and (max-width: 650px) {
        text-align: center;
      }

      @media screen and (max-width: 450px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 350px) {
        font-size: 2rem;
      }
    }

    p {
      font-size: 1.5rem;
      margin-top: 3rem;

      @media screen and (max-width: 650px) {
        text-align: center;
      }

      @media screen and (max-width: 350px) {
        font-size: 1rem;
      }


    }

  }


  img {

    width: 45%;
    max-width: 45rem;
    height: auto;
    align-self: flex-end;


    @media screen and (max-width: 650px) {
      order: 1;
      width: 100%;
    }
  }
}


.sectionCardStyle {
  padding: 11rem 0;
  position: relative;

  @media screen and (max-width: 650px) {
    padding: 3.5rem 0;
  }

  & > .container {
    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url("../../img/carosule-bg.png");
    background-position: center;
    background-size: cover;


    &:after {
      content: '';
      display: block;

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      background: #46F708;
      mix-blend-mode: color;

    }

  }


  h2 {
    font-size: 4rem;
    font-weight: 600;
  }

  .carosule {
    margin: 8.3rem 0 4.5rem;
    width: 80%;
    height: 36rem;

    @media screen and (max-width: 650px) {
      margin: 1rem 0 3.5rem;
    }


    @media screen and (max-width: 850px) {
      width: 90%;
    }


    @media screen and (max-width: 650px) {
      width: 100%;
    }


    .splide,
    .splide__track {

      height: 100%;
    }

    .splide__arrow {
      opacity: 1;
      background-color: transparent;


      @media screen and (max-width: 650px) {
        display: none;
      }


    }

    .splide__pagination__page {
      opacity: 1;
      background-color: #C4C4C4;

      &.is-active {
        background-color: #46F708;
      }

    }


    .splide__slide {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-out;


      img {
        //width: 40%;
        width: 70%;

        transform: scale(0.60);
        transform-origin: center;

        height: auto;
        max-height: 100%;
        transition: all 0.2s ease-out;

        @media screen and (max-width: 450px) {
          width: 100%;
        }
      }

      &.is-active {
        img {
          //width: 72%;
          transform: scale(1);

        }
      }

    }

    .splide--nav > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active,
    .splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
      border-color: transparent !important;
    }
  }

  .btn {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  &__textSm {
    font-size: 1.2rem;
    color: #D3D3D3;
    font-style: italic;
  }
}


.sectionWorking {
  padding: 11rem 0 9.5rem;
  width: 100%;
  position: relative;

  @media screen and (max-width: 650px) {
    padding: 6.25rem 0 0;
  }


  h2 {
    font-size: 4rem;
    text-shadow: 0 0.33rem 0.33rem rgba(0, 0, 0, 0.45);
    font-weight: 600;
    margin-bottom: 8.5rem;

    @media screen and (max-width: 650px) {
      font-size: 3rem;
      margin-bottom: 4rem;
    }

  }


  &__stepsCont {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    user-select: none;

    @media screen and (max-width: 450px) {

      align-items: center;
      justify-content: center;
    }


  }


  &__step {

    width: 33.33%;
    height: 36rem;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    padding: 3.75rem 3.16rem 2rem;
    transition: all 0.2s;


    @media screen and (max-width: 650px) {
      width: 50%;
    }

    @media screen and (max-width: 450px) {
      width: 90%;
    }


    &:hover {
      background: #1C1B1E;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.85);
    }


    &__text {
      width: 100%;
      margin-bottom: 2rem;

      @media screen and (max-width: 450px) {
        text-align: center;
      }


      p {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }

      h4 {
        font-size: 2rem;
        font-weight: 600;
        text-shadow: 0 0.33rem 0.33rem rgba(0, 0, 0, 0.45);

      }

    }

    img {
      //align-self: flex-end;
      //justify-self: flex-end;
      //width: 90%;
      width: auto;
      height: auto;
      max-height: 16rem;
      //max-height: 50%;
      //height: 20rem;

      margin-bottom: 1.7rem;


    }


    button {
      align-self: flex-start;
      justify-self: flex-start;

      @media screen and (max-width: 450px) {
        align-self: center;
        justify-self: center;
      }

    }

  }

  .step-btn {
    justify-content: flex-start;


    button {
      margin-top: 25%;
      margin-left: 10%;

      @media screen and (max-width: 450px) {
        margin-top: 15%;
        margin-left: unset;
      }

    }

  }


}


.sectionAbout {
  padding: 9.91rem 0;
  position: relative;

  text-align: center;

  h2,
  p {
    width: 74%;

    @media screen and (max-width: 550px) {
      width: 80%;
    }

  }

  h2 {
    font-size: 3rem;
    color: black;
    font-weight: 600;
    margin-bottom: 1.3rem;

    @media screen and (max-width: 550px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 400px) {
      font-size: 2rem;
    }

  }

  p {
    font-size: 2rem;
    color: black;
    font-weight: 400;

    @media screen and (max-width: 550px) {
      font-size: 1.5rem;
    }

    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }


  }


}


.sectionPros {
  padding: 11rem 0 12.5rem;
  width: 100%;
  position: relative;


  @media screen and (max-width: 650px) {
    padding: 6rem 0;


  }


  h2 {
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 0 0.33rem 0.33rem rgba(0, 0, 0, 0.45);

    margin-bottom: 5rem;

    @media screen and (max-width: 650px) {
      width: 80%;
      font-size: 3.5rem;
      margin-bottom: 2rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 3rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 2.5rem;
    }

    @media screen and (max-width: 350px) {
      font-size: 2rem;
    }


  }


  &__prosCont {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    user-select: none;
    //margin-bottom: 5rem;

    @media screen and (max-width: 450px) {

      align-items: center;
      justify-content: space-around;
    }


  }


  &__pro {

    width: 33.33%;
    height: 28rem;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    --linear-gradient-id: url('#lgrad-1');

    @media screen and (max-width: 650px) {
      width: 45%;
      height: 20rem;

    }

    @media screen and (max-width: 450px) {
      height: 17rem;
    }

    img,
    svg {
      width: auto;
      height: auto;
      max-height: 7rem;

      margin-top: 19%;
      margin-bottom: 2rem;

      //fill:url('#lgrad-');

      path {
        //fill:url('#lgrad-p');
        //stroke:url('#lgrad-p');
      }


      @media screen and (max-width: 650px) {
        max-height: 5.5rem;
      }

      @media screen and (max-width: 550px) {
        max-height: 5rem;
      }


    }

    svg {
      fill: var(--linear-gradient-id);

      path {
        fill: var(--linear-gradient-id);
        stroke: var(--linear-gradient-id);
      }
    }

    svg:nth-child(2) {
      width: 0;
      height: 0;
      overflow: hidden;
      position: absolute;
      //pointer-events: ;

    }

    p {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      text-align: center;
      color: white;


      @media screen and (max-width: 450px) {
        font-size: 1rem;
      }

    }

    stop,
    p {
      transition: 0.2s;
    }

    &:hover {

      stop:first-child {
        stop-color: #46F708;
      }

      stop:last-child {
        stop-color: #E1F600;
      }

      p:not(.pro-last) {
        background: linear-gradient(86.92deg, #46F708 7.61%, #E1F600 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        //color: transparent;
      }

    }


  }

  .pro1 {
    --linear-gradient-id: url('#lgrad-1');
  }

  .pro2 {
    --linear-gradient-id: url('#lgrad-2');
  }

  .pro3 {
    --linear-gradient-id: url('#lgrad-3');
  }

  .pro4 {
    --linear-gradient-id: url('#lgrad-4');
  }

  .pro5 {
    --linear-gradient-id: url('#lgrad-5');
  }

  .pro6 {
    --linear-gradient-id: url('#lgrad-6');

  }

  .pro7 {
    --linear-gradient-id: url('#lgrad-7');
  }

  .pro8 {
    --linear-gradient-id: url('#lgrad-8');
  }

  .pro9 {
    --linear-gradient-id: url('#lgrad-9');
  }


  &__btns {

    flex-basis: 100%;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: unset;


    @media screen and (max-width: 650px) {
      flex-basis: unset;
      height: 20rem;
      flex-flow: column;
      justify-content: flex-start;
    }


    @media screen and (max-width: 450px) {
      height: 17rem;
    }


    p {
      font-size: 2rem;
      margin-right: 3rem;

      @media screen and (max-width: 650px) {
        font-size: 1.5rem;
        margin-right: 0;
        margin-bottom: 1.5rem;


        margin-top: 19%;


      }

    }

  }


}
.cart {
  .heading-main {
    margin: 6rem 0;
  }
}

.tableHeader {
  &__title {
    //padding-left: 4rem;

    width: 70%;

    @media screen and (max-width: 600px) {
      width: 55%;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      min-width: 10rem;
      font-size: 1.5rem;
      font-weight: 500;

      @media screen and (max-width: 600px) {
        margin-left: 0.5rem;
        font-size: 2rem;
        min-width: 14rem;
      }
    }
  }

  &__info {
    width: 15%;
    font-weight: 500;
    font-size: 1.5rem;

    @media screen and (max-width: 600px) {
      width: 22.5%;
    }
  }

  &__meta {
    width: 15%;
    //padding-right: 5.5rem;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 600px) {
      width: 22.5%;
      padding-right: 1.5rem;
      justify-content: flex-end;
      white-space: nowrap;
    }
  }
}

.cartItem {
  height: 14.5rem;

  &__left {
    width: 70%;

    @media screen and (max-width: 600px) {
      width: 55%;
    }
  }

  &__right {
    width: 30%;
    @media screen and (max-width: 600px) {
      width: 45%;
    }
  }

  &__img {
    width: 20%;

    img {
      width: 5.5rem;
      height: auto;
    }
  }

  &__prodAboutCont {
    width: 80%;
    display: flex;

    @media screen and (max-width: 600px) {
      flex-flow: column;
      justify-content: center;
      align-items: center;
      padding-left: 1.5rem;
    }
  }

  &__text {
    width: 50%;
    padding-left: 3.75rem;

    @media screen and (max-width: 1000px) {
      padding-left: 1.75rem;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      padding-left: 0;
    }

    &__name {
      font-size: 2rem;

      @media screen and (max-width: 750px) {
        font-size: 1.75rem;
      }

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    &__size {
      font-size: 2rem;

      @media screen and (max-width: 750px) {
        font-size: 1.75rem;
      }

      @media screen and (max-width: 600px) {
        font-size: 1.3rem;
      }
    }
  }

  &__preview {
    width: 50%;

    cursor: pointer;
    align-self: flex-start;
    padding-top: 3.66rem;
    padding-left: 2rem;

    @media screen and (max-width: 600px) {
      padding-top: 0;
      width: 100%;
      padding-left: 0;
      margin-top: 1rem;
    }

    img {
      width: 3rem;
      height: auto;

      @media screen and (max-width: 600px) {
        width: 1.7rem;
      }
    }

    p {
      margin-left: 2rem;
      color: #f3ea32;
      text-decoration: underline;
      font-size: 2rem;

      @media screen and (max-width: 600px) {
        font-size: 1.3rem;
        margin-left: 0.9rem;
      }
    }
  }

  &__about {
    width: 50%;
    justify-content: center;

    &__counter {
      width: 80%;
      border: 0.125rem solid white;

      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0.3rem 0;

      input[type='number'] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        //appearance: textfield;
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      input {
        text-align: center;

        width: 50%;
        background-color: transparent;
        color: white;
        font-size: 2rem;
        padding: 0 1rem;

        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }

      span {
        width: 1.66rem;
        height: 1.66rem;
        border: 0.16rem solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        //font-weight: 600;
        border-radius: 100%;
        cursor: pointer;
        z-index: 100;
        user-select: none;
      }
    }

    &__text {
      font-size: 2rem;

      @media screen and (max-width: 750px) {
        font-size: 1.75rem;
      }

      @media screen and (max-width: 600px) {
        font-size: 1.7rem;
      }
    }
  }

  &__detail {
    width: 50%;

    padding-right: 4.5rem;
    justify-content: flex-end;
    font-size: 2rem;

    @media screen and (max-width: 750px) {
      font-size: 1.75rem;
    }

    @media screen and (max-width: 600px) {
      padding-right: 1.5rem;
      font-size: 1.7rem;
    }
  }
}

.cartAddon {
  height: 12.5rem;

  @media screen and (max-width: 600px) {
    .cartItem__text p {
      white-space: nowrap;
    }
  }

  .btn--primary {
    padding: 1.5rem 2.5rem;

    @media screen and (max-width: 400px) {
      padding: 0.5rem 1rem;
      font-size: 1.4rem;
    }
  }

  .shippingLabelSm {
    @media screen and (min-width: 600px) {
      display: none;
    }
  }

  .shippingLabel {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &__kitPrice {
    font-size: 2rem;

    @media screen and (max-width: 400px) {
      font-size: 1.8rem;
    }
  }
}

.cartSubtotal {
  height: 10.5rem;

  &__text {
    margin-right: 7.25rem;

    @media screen and (max-width: 600px) {
      margin-right: 3rem;
      font-size: 1.5rem;
    }
  }

  &__price {
    min-width: 15rem;
    padding-right: 1rem;

    @media screen and (max-width: 600px) {
      min-width: 11rem;
    }
  }
}

.cartCta {
  min-height: 9.5rem;

  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: 750px) {
    flex-flow: column;
    margin-top: 3rem;
  }

  &__email {
    height: 4rem;

    @media screen and (max-width: 750px) {
      align-self: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4rem;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 300;
      font-style: italic;
      font-size: 1.5rem;

      @media screen and (max-width: 400px) {
        font-size: 1rem;
        width: 50%;
        justify-content: flex-start;
      }
    }

    input {
      margin-left: 1.6rem;
      height: 100%;
      width: 60%;
      min-width: 27.5rem;

      background-color: transparent;
      border-bottom: 1px solid white;
      color: white;
      font-size: 1.5rem;

      @media screen and (max-width: 400px) {
        min-width: unset;
        height: 70%;
        width: 50%;
        font-size: 1rem;
      }
      @media screen and (max-width: 340px) {
        height: 50%;
      }

      &::placeholder {
        color: white;
      }
    }
  }

  button {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba($color-primary, 0.5);

    width: 30rem;
    height: 6.66rem;

    @media screen and (max-width: 600px) {
      width: 25rem;
      height: 5.5rem;
    }

    @media screen and (max-width: 400px) {
      width: 50%;
      height: 4.5rem;
    }

    span {
      color: black;

      @media screen and (max-width: 400px) {
        font-size: 1.8rem;
      }
    }

    img {
      width: 3.1rem;
      height: 3.1rem;

      @media screen and (max-width: 400px) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.cartAddress {
  margin: 7.5rem 0;

  &__form {
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    @media screen and (max-width: 450px) {
      justify-content: center;
      margin-top: 0;
    }

    .inputGroup {
      margin-top: 2.25rem;
      width: 100%;

      display: flex;
      flex-flow: column;
      font-size: 1.5rem;

      @media screen and (max-width: 450px) {
        font-size: 1.8rem;
        margin-top: 2rem;
      }

      input,
      select {
        width: 100%;
        margin-top: 1rem;
        height: 5.3rem;
        color: black;
        padding: 0 1.83rem;

        border: 1px solid #a2a6b0;
        border-radius: 0.33rem;

        @media screen and (max-width: 450px) {
          margin-top: 0;
        }

        &:focus {
          outline: 1px solid $color-primary-dark;
          border-color: $color-primary-dark;
        }
      }
    }

    &__left {
      width: 45%;
      @media screen and (max-width: 550px) {
        width: 48%;
      }
      @media screen and (max-width: 450px) {
        width: 95%;
      }
    }

    &__right {
      width: 45%;
      @media screen and (max-width: 550px) {
        width: 48%;
      }

      @media screen and (max-width: 450px) {
        width: 95%;
      }
    }

    &__btn {
      margin-left: auto;
      margin-top: 7rem;

      width: 23%;
      height: 6.5rem;

      font-size: 2rem;

      @media screen and (max-width: 450px) {
        margin-right: 3%;
        margin-top: 4.4rem;
        width: auto;
        font-size: 1.8rem;
        height: unset;

        padding: 1rem 3rem;
      }
    }
  }
}

.orderItem {
  padding: 3rem 0;
  border-bottom: 1px solid $color-primary;
  &:last-of-type {
    margin-bottom: 4rem;
  }

  .cartItem {
    height: 11.5rem;
  }

  .orderAddons {
    height: 8.5rem;
    padding-left: 15rem;

    @media screen and (max-width: 600px) {
      padding-left: 10%;
    }
    @media screen and (max-width: 350px) {
      padding-left: 5%;
    }

    &__subPrice {
      width: 100%;
      text-align: right;
      padding-right: 4.5rem;
      font-size: 2rem;

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
}

.emailVerify {
  height: 90vh;
  min-height: 65rem;
  //padding: 6.25rem 0;

  .container {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  &__imgCont {
    max-height: 55%;
    width: auto;
    //margin: 6.25rem 0;
  }

  .btn {
    font-size: 2rem;
    padding: 1rem 7rem;
  }
}

.emailVerified {
  height: 40vh;
  min-height: 40rem;

  .container {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
  }
}

html {
  --progress-color: #{$color-primary};
}

input[type='range'] {
  -webkit-appearance: none;
  height: 0.3125rem;
  outline: none;
  border-radius: 10px;
}

//1.25rem
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: $color-primary;
  box-shadow: unset;
  border: unset;
  //margin-top: -0.635rem;
  margin-top: -0.5925rem;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: $color-primary;
  box-shadow: unset;
  border: unset;
  cursor: pointer;
}

input[type='range']::-ms-thumb {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  background: $color-primary;
  box-shadow: unset;
  border: unset;
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  box-shadow: none;
  border: none;
  height: 0.3125rem;
  width: 100%;
  cursor: pointer;
  border-radius: 10px !important;
}

input[type='range']::-moz-range-track {
  box-shadow: none;
  border: none;
  background: #d7ddee;
  height: 0.3125rem;
  width: 100%;
  cursor: pointer;
  border-radius: 10px !important;
}

input[type='range']::-ms-track {
  box-shadow: none;
  border: none;
  background: #d7ddee;
  height: 0.3125rem;
  width: 100%;

  cursor: pointer;
  border-radius: 10px !important;
}

//input[type='range']::-webkit-slider-thumb {
//  width: 6px;
//  -webkit-appearance: none;
//  height: 12px;
//  background: black;
//  border-radius: 2px;
//}

.footer {
  width: 100%;
  margin-top: 4.75rem;

  &__container {
    width: 90%;
    max-width: 115rem;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8rem;

    @media screen and (max-width: 650px) {
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 4rem;
    }

    &__logo {
      height: 2.75rem;

      @media screen and (max-width: 650px) {
        height: 4.125rem;
        object-fit: contain;
      }
    }

    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 650px) {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 6.875rem 0;
      }

      li {
        font-size: 1.33rem;
        font-weight: 500;
        line-height: 1.8;

        a {
          color: white;
        }

        @media screen and (max-width: 650px) {
          font-size: 2rem;
        }

        &:not(:last-of-type) {
          margin-right: 2.83rem;

          @media screen and (max-width: 650px) {
            margin-right: 0;
            margin-bottom: 2.875rem;
          }
        }
      }
    }

    &__follow {
      p {
        @media screen and (max-width: 760px) {
          display: none;
        }
      }

      img {
        width: 3rem;
        height: 3rem;

        @media screen and (max-width: 650px) {
          width: 4.75rem;
          height: 4.75rem;

          &:last-of-type{
            margin-left: 3rem;
          }
        }
      }
    }
  }

  &__text {
    margin-bottom: 5rem;
    font-size: 1.2rem;
    line-height: 1.8;
    font-weight: 600;
    text-align: left;

    color: #ffffff;

    text-shadow: 0 0.33rem 0.33rem rgba(0, 0, 0, 0.45);
  }

  &__bottom {
    width: 100%;
    padding: 2.33rem 0 1.83rem;
    background: linear-gradient(269.66deg, #ffff00 -19.26%, #46f708 111.31%);
    color: black;

    font-size: 1.2rem;
    line-height: 1.8;
  }
}

.cardCreator {
  position: relative;

  width: 100%;

  & > .container {
    padding-bottom: 4.15rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 650px) {
      justify-content: center;
      align-items: center;
    }
  }

  &__arrow {
    position: absolute;
    top: 37rem;
    left: 0;
    transform: translateX(-50%);

    z-index: 100000;

    @media screen and (max-width: 650px) {
      display: none;
    }

    img {
      cursor: pointer;
      width: 4.33rem;
      height: 4.33rem;
    }
  }

  .arrowRight {
    left: unset;
    right: 0;
    transform: translateX(50%);
  }

  .arrowLeft {
    transform: translateX(-50%) rotate(-180deg);
  }
}

.creatorHalfContainer {
  width: 50%;
  position: relative;
  margin-top: 4.15rem;

  @media screen and (max-width: 650px) {
    width: 90%;
    margin-top: 1rem;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  h2 {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.45);
  }

  .cardsContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .headingContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 2.5rem 0;

    @media screen and (max-width: 650px) {
      margin: 1.5rem 0;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 600;

      text-shadow: 0 0.33rem 0.33rem rgba(0, 0, 0, 0.45);
    }

    p {
      font-size: 1rem;
      font-style: italic;

      span {
        margin-right: 0.75rem;
      }
    }

    .infoBoxImg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.creatorContentContainer {
  width: 76%;
  margin-top: 5rem;

  background: #1e1f25;
  box-shadow: 0 0 0.5rem 0.33rem rgba(0, 0, 0, 0.15);

  padding: 1rem 3.08rem 3.41rem 3.91rem;

  @media screen and (max-width: 1100px) {
    width: 90%;
  }

  @media screen and (max-width: 900px) {
    padding: 1rem 1.08rem 3.41rem 1.91rem;
  }

  @media screen and (max-width: 650px) {
    margin-top: 2rem;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
}

* {
  //border: 1px solid hotpink!important;
}

.toast {
  z-index: 100000;

  position: fixed;
  top: 2rem;
  right: 2rem;
  background-color: $color-primary-dark;
  color: white;

  min-width: 10rem;
  min-height: 11rem;
  border-radius: 6px;

  padding: 1.5rem 2rem;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;

  transform: translateX(200%);
  transition: 0.3s ease-in-out;

  p {
    width: 90%;
    font-size: 1.5rem;
  }

  .cross {
    position: absolute;
    top: 1.5rem;
    right: 2rem;

    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;

    transform: translateY(50%);

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background-color: white;
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(135deg);
    }
  }

  &__checkboxCont {
    label {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    input {
      border: unset;
      outline: unset;
      .grayBg {
        background-color: $color-gray-600;
      }
    }

    span {
      margin-left: 0.5rem;
    }
  }
}

.slideOut {
  transform: translateX(200%);
}
.slideIn {
  transform: translateX(0%);
}

.countryCard {
  //padding: 1.25rem 2.25rem 1.135rem;
  //padding: 1.25rem 0.5rem 1.135rem 2.25rem;
  //padding: 1.25rem 0.5rem 1.135rem;

  padding: 1.25rem 0.5rem 1.135rem 0;
  width: 31.15%;
  //height: 33.33%;
  height: 27%;
  margin-bottom: 1rem;

  display: flex;
  //justify-content: center;
  justify-content: flex-start;
  align-items: center;
  user-select: none;

  &.active {
    border-color: $color-primary;
  }

  img {
    min-width: 3.16rem;
    width: 21.51%;
    height: auto;
    margin: 0 0.5rem;
  }

  &__text {
    margin-left: 0.5rem;

    //margin-left: 1.5rem;

    h3 {
      word-break: break-word;

      font-size: 1rem;
      font-weight: 400;
      //text-align: left;
      margin-bottom: 0.4rem;
    }
  }
}

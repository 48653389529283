.btn {
  font-size: 1rem;
  //font-weight: 400;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0.66rem 2.666rem;
  border-radius: 4px;

  &--transparent {
    border: 1px solid #8d8d8d;

    &:hover,
    &:hover {
      border: 1px solid $color-primary;
      color: $color-primary;
    }
  }

  &--primary {
    background-color: $color-primary;
    color: black;
    padding: 0.66rem 3rem;

    &--transparent {
      color: $color-primary;
      border: 1px solid $color-primary;
      background-color: transparent;
    }
  }

  &--lg {
    width: auto;
    min-width: 12rem;
    padding: 1.5rem 0;
  }

  &--gradient {
    background: linear-gradient(86.92deg, #46f708 7.61%, #e1f600 100%);
    width: auto;
    padding: 1.5rem 5.83rem;

    color: black;
    font-size: 1.5rem;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.d-flex {
  display: flex;
}

.d-flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}


.flex-column {
  flex-flow: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center !important;
}

.justify-end {
  justify-content: end !important;
}

.flex-start {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.heading-main {
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  text-shadow: 0 0.33rem 0.33rem rgba(0, 0, 0, 0.45);
}

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.text-1 {
  font-size: 1rem;
}

.text-2 {
  font-size: 1.5rem;
}

.text-3 {
  font-size: 2rem;
}

.text-4 {
  font-size: 2.5rem;
}

.text-5 {
  font-size: 3rem;
}

.semi-bold {
  font-weight: 600;
}

.text-bold {
  font-weight: bold;
}

.w-full {
  width: 100%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-15 {
  width: 15%;
}

.h-full {
  height: 100%;
}

// -----------------------------------------------------
// ===================== Padding ========================
// -----------------------------------------------------

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pt-5 {
  padding-top: 2.5rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pb-5 {
  padding-bottom: 2.5rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pl-3 {
  padding-left: 1.5rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.pl-5 {
  padding-left: 2.5rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pr-3 {
  padding-right: 1.5rem !important;
}

.pr-4 {
  padding-right: 2rem !important;
}

.pr-5 {
  padding-right: 2.5rem !important;
}

// -----------------------------------------------------
// ===================== Margin ========================
// -----------------------------------------------------

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mt-5 {
  margin-top: 2.5rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 2.5rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-3 {
  margin-left: 1.5rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.ml-5 {
  margin-left: 2.5rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.mr-5 {
  margin-right: 2.5rem !important;
}

.absolute-top-right {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

.fadeOut {
  z-index: -100;
  opacity: 0;
  transition: opacity 0.3s;
}

.fadeIn {
  position: relative !important;
  z-index: 10000;
  opacity: 1;
  transition: opacity 0.3s 0.3s;
}

.doted-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e1f25;
  z-index: -1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url('../../img/creatorBG.jpg');
    background-size: 170%;
    mix-blend-mode: multiply;
    opacity: 0.25;
  }
}

.doted-bg-gradient {
  background: linear-gradient(266.8deg, #e1f600 12.31%, #46f708 97.76%);

  &:after {
    background-size: 100%;
    opacity: 0.1;
  }
}

.p-relative {
  position: relative;
}

.border-bottom-primary {
  border-bottom: 1px solid $color-primary;
}

.border-dashed {
  border-style: dashed !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.cardCreatorPage {
  & > .container {
    gap: 4.75rem;
    padding-top: 4.75rem;

    @media screen and (max-width: 1100px) {
      width: 90%;
    }

    @media screen and (max-width: 650px) {
      width: 95%;
      padding-top: 4rem;
    }
  }

  &__stages {
    width: 100%;
    gap: 7.16rem;

    @media screen and (max-width: 650px) {
      gap: 0;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      width: 98%;
      position: absolute;
      top: 6rem;
      z-index: 1000;
      left: 50%;
      transform: translateX(-50%);
    }

    &__item {
      font-size: 1.5rem;
      font-weight: 500;

      @media screen and (max-width: 650px) {
        width: 23%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.18rem;
      }

      &.active {
        color: #ffff00;
      }

      button:focus {
        color: #ffff00;
      }
    }
  }
}

.step-1,
.step-2,
.step-3,
.step-4 {
  @media screen and (max-width: 650px) {
    .creatorContentContainer {
      margin-top: 0;
    }
    h2 {
      display: none;
    }
  }
}

.errorMessage {
  font-size: 1.5rem;
  color: crimson;
  //font-weight: bold;
  margin-bottom: 0.8rem;
}

.navigation-buttons {
  padding-top: 1.5rem;
  @media screen and (min-width: 650px) {
    display: none;
  }

  .back-btn-sm {
    margin-right: auto;
    margin-left: 5%;
  }

  .next-btn-sm {
    margin-left: auto;
    margin-right: 5%;
  }
}

.aboutCard {
  width: 32%;
  margin-bottom: 1.3rem;
  border: 1px solid #8d8d8d;
  padding: 1.3rem 0.5rem;
  padding-left: 0;
  cursor: pointer;
  user-select: none;

  &.active {
    border-color: $color-primary;
  }

  img {
    max-width: 30%;
    width: 30%;
    height: auto;
    margin: 0 1rem;
  }

  &__text {
    display: flex;
    flex-flow: column;
    justify-content: center;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      text-align: left;
      margin-bottom: 0.4rem;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      color: $color-secondary;
      text-align: left;
    }
  }
}

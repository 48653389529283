.sizeCard {
  width: 24%;
  height: 24.5rem;

  border: 1px solid #8d8d8d;

  align-items: center;
  justify-content: flex-end;
  user-select: none;
  cursor: pointer;
  text-align: center;

  @media screen and (max-width: 850px) {
    width: 48%;
    margin-bottom: 1.5rem;
  }

  &.active {
    border-color: $color-primary;
  }

  img {
    //margin-bottom: 0.33rem;
    max-height: 75%;
    width: auto;
  }
}

.smallCard {
  img {
    margin-bottom: 0.75rem;
    height: 35%;
  }
}

.mediumCard {
  img {
    margin-bottom: 0.33rem;
    height: 47%;
  }
}

.largeCard {
  img {
    height: 60%;
  }
}

.xlargeCard {
  img {
    height: 75%;
  }
}

.cardAddImage {
  .creatorContentContainer {
    padding-left: 2.66rem;
  }

  &__imgPreview {
    width: 100%;
    height: 18.41rem;

    &__initial {
      width: 100%;
      height: 100%;

      border: 1.5px dashed #46f708;
      box-sizing: border-box;
      border-radius: 5px;

      label {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      img {
        width: 3.5rem;
        height: auto;
      }

      p {
        margin-top: 1.33rem;
        font-size: 1rem;
        color: $color-primary;

        span {
          text-decoration: underline;
          font-style: italic;
        }
      }

      input {
        visibility: hidden;
        position: absolute;
        z-index: -1000;
        pointer-events: none;
      }
    }
  }

  &__range {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__inputCont {
      width: 85%;

      position: relative;

      input {
        width: 100%;
      }

      .range-value {
        position: absolute;
        top: 2rem;
        left: 0;
        transform: translateX(0);

        text-align: center;
        font-size: 1rem;
        color: #8d8d8d;
      }
    }
    //input,div{
    //  width: 85%;
    //}
  }
}

.cardPreview {
  justify-content: flex-start !important;
  user-select: none;

  @media screen and (max-width: 650px) {
    gap: 3rem;

    .previewHeadline{
      display: none;
    }
  }

  .creatorContentContainer {
    padding: 0;
    min-height: 55rem;
    position: relative;

    @media screen and (max-width: 650px) {
      min-height: unset;
    }
  }

  &__cardContainer {
    position: relative;
    margin: 4.1rem 0 2.9rem;
    min-width: 60%;
    max-width: 90%;
    width: 31rem;
    height: auto;

    @media screen and (max-width: 650px) {
      min-width: 50%;
      width: 22rem;
      margin: 1.5rem 0;
    }
  }

  &__previewImg {
    width: 100%;
  }

  &__cardContent {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    &.active {
      input {
        border-bottom: 0.085rem solid white;
        pointer-events: all;

        &:focus,
        &:active {
          color: var(--inputTextColor);
          box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.45);
        }
      }
    }

    input {
      pointer-events: none;

      text-transform: uppercase;
      background: transparent;
      //color: $color-secondary;
      color: var(--inputTextColor);
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0.2rem 0;
      width: 100%;
      border-bottom: 0.085rem solid transparent;
      transition: all 0.15s;
    }

    input.inputError {
      border-color: crimson !important;
    }

    &__top {
      width: 90%;

      display: flex;
      justify-content: center;
      align-items: flex-end;

      &__about {
        width: 20%;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        margin-right: 1rem;

        input {
          width: 90%;
          font-size: 2rem;
          font-weight: 400;
          text-align: center;
          height: 2.5rem;
          margin-bottom: 0.5rem;
        }

        .cardFlag {
          width: 70%;
          min-height: 2.5rem;
        }

        &__num {
          font-weight: 600 !important;
          font-size: 3.5rem !important;
          height: 4rem !important;

          @media screen and (max-width: 650px) {
            font-size: 3rem !important;
          }
        }

        &__text {
          margin-bottom: 0.5rem;
        }

        .sepratorLine {
          width: 50%;
          min-height: 0.25rem;
          height: 0.25rem;
          background-color: white;
          transition: all 0.15s;
        }

        .cardFlag {
          margin: 0.75rem 0;
        }
      }

      &__img {
        width: 55%;
        height: 16rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &__placeholder {
          height: 100%;
          width: 100%;
          background-color: #6c757d;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          p {
            font-size: 1.5rem;
            width: 90%;
          }
        }

        img {
          width: 100%;
          height: auto;
          max-height: 100%;
          object-fit: contain;
        }
      }
    }

    &__name {
      margin-top: 0.3rem;
      width: 90%;

      input {
        text-align: center;
        font-size: 3rem;
        font-weight: 500;
        color: var(--inputTextColor);
        line-height: 1.3;
        border-bottom: 0.085rem solid white;
      }
    }

    &__stats {
      margin-top: 1.25rem;
      width: 90%;
      min-height: 25%;

      display: flex;
      justify-content: center;
      align-items: flex-start;

      &__left {
        height: 100%;
        width: 50%;
        border-right: 0.085rem solid white;
        justify-content: flex-start;
      }

      &__right {
        height: 100%;
        width: 50%;
        justify-content: flex-start;
      }

      .statsGroup {
        width: 75%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        margin-bottom: 0.5rem;

        input {
          font-size: 2rem;
          height: 2.5rem;
          width: 25%;
          //text-align: center;
          font-weight: 500 !important;
          text-align: left;

          &:last-of-type {
            width: 40%;
            font-weight: 400 !important;
          }

          @media screen and (max-width: 650px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  &__borderRight {
    position: absolute;
    top: 5%;
    right: 0;
    width: 0.1rem;
    height: 95%;
    background-color: $color-primary;

    @media screen and (max-width: 650px) {
      top: unset;
      right: unset;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0.1rem;
    }
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8.33rem;
  box-shadow: 0 0.33rem 0.66rem rgba(0, 0, 0, 0.35);
  transition: all 0.4s ease-in-out;

  @media screen and (max-width: 650px) {
    &.hideHeader {
      margin-bottom: -8.33rem;
      transform: translateY(-8.33rem);
    }
  }

  &__container {
    width: 100%;
    padding: 0 7%;
    justify-content: space-between;
  }

  &__logo {
    width: 21.83rem;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    width: 55%;
    min-width: 40rem;
    max-width: 80rem;

    transform-origin: top;
    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 860px) {
      width: 65%;
    }

    @media (max-width: 650px) {
      width: 100%;
      min-width: unset;
      max-width: unset;

      display: flex;
      flex-flow: column;
      align-items: flex-start;
      margin-top: 1rem;

      position: absolute;
      top: 100%;
      left: 0;

      z-index: 99999;
      background-color: $color-primary;
      padding-top: 1rem;
    }

    &.navSm {
      width: 30%;
    }

    li {
      //&:not(:last-of-type){
      //margin-right: 4.5rem;
      //}

      @media (max-width: 650px) {
        width: 100%;
        height: 6rem;
        border-bottom: 1px dashed rgba(#fff, 0.7);
      }

      a {
        font-size: 1.5rem;
        color: $color-text;

        @media (max-width: 650px) {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
        }

        &:hover {
          color: $color-primary;

          @media (max-width: 650px) {
            color: $color-secondary;
          }
        }
      }
    }
  }

  .hideNav {
    @media (max-width: 650px) {
      transform: scaleY(0);
      height: 0;
      opacity: 0;
      overflow: hidden;
      padding-top: 0;
      position: absolute;
    }
  }

  .hamburgerMenu {
    transition: all 0.2s ease-in-out;

    margin: 0 1rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    z-index: 100;
    cursor: pointer;
    width: 3rem;
    height: 2.5rem;

    div {
      width: 100%;
      height: 2px;
      background-color: white;
      transition: all 0.2s ease-in-out;
    }

    @media (min-width: 650px) {
      display: none;
    }
  }

  .makeCross {
    @media (max-width: 650px) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      transition: all 0.2s ease-in-out;

      & > :nth-child(1) {
        transform: rotate(45deg);
      }

      & > :nth-child(2) {
        transform: rotate(-45deg) translate(1px, -1px);
      }

      & > :nth-child(3) {
        display: none;
      }
    }
  }
}

.headerMessage {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.4s ease-in-out;
  z-index: 100;

  padding: 1.5rem 0 0;
  font-size: 1.25rem;
  cursor: pointer;

  @media screen and (min-width: 651px) {
    transform: translateY(-100%);
  }

  &.hideMessage {
    transform: translateY(-100%);
  }


}